<template>
    <div class="container" :style="{ backgroundImage: 'url(' + require('../assets/bg_login.jpg') + ')' }">
        <transition :name="`slide-${register ? 'in' : 'out'}`">
            <div class="login-wrapper" @keyup.enter="doRegister" v-if="register" key="register">
                <el-page-header @back="register = false" title="登录" style="width: 350px; line-height: 60px">
                    <!-- <div class="register-title" slot="content">注册账号</div> -->
                </el-page-header>

                <el-form :model="registerInfo" style="width: 350px" ref="registerForm">
                    <el-form-item prop="username" :rules="{ required: true, message: '请输入用户名', trigger: 'blur' }">
                        <el-input v-model="registerInfo.username" placeholder="用户名"> </el-input>
                    </el-form-item>
                    <el-form-item prop="password" :rules="{ required: true, message: '请输入密码', trigger: 'blur' }">
                        <el-input v-model="registerInfo.password" placeholder="密码" type="password"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button :loading="loading" @click="doRegister" type="primary" style="width: 100%">
                            注册
                        </el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div class="login-wrapper" @keyup.enter="login" v-else key="login">
                <div class="title">欢迎登录</div>
                <el-tabs v-model="activeName" stretch class="tab-list">
                    <el-tab-pane label="用户名登陆" name="first">
                        <el-form :model="userInfo" style="width: 350px" ref="form">
                            <el-form-item
                                prop="username"
                                :rules="{ required: true, message: '请输入用户名', trigger: 'blur' }"
                            >
                                <el-input v-model="userInfo.username" placeholder="用户名"> </el-input>
                            </el-form-item>
                            <el-form-item
                                prop="password"
                                :rules="{ required: true, message: '请输入密码', trigger: 'blur' }"
                            >
                                <el-input v-model="userInfo.password" placeholder="密码" type="password"></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button :loading="loading" @click="login" type="primary" style="width: 100%"
                                    >登录
                                </el-button>
                            </el-form-item>
                            <!-- <el-form-item>
                                <el-button :loading="loading"
                                    @click="phonelogin" type="primary"
                                    style="width: 100%;">手机登录</el-button>
                            </el-form-item> -->
                            <el-form-item label="">
                                <el-checkbox v-model="rememberMe">7天内免登录 </el-checkbox>
                                <el-button type="text" style="float: right" @click="register = true"
                                    >注册账号
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                    <el-tab-pane label="验证码登陆" name="second">
                        <el-form :model="userInfo" style="width: 350px" ref="form2">
                            <el-form-item
                                prop="phone"
                                :rules="{ required: true, message: '请输入手机号', trigger: 'blur' }"
                            >
                                <el-input v-model="userInfo.phone" name="phone" placeholder="手机号"> </el-input>
                            </el-form-item>
                            <el-form-item prop="code" :rules="{ required: true, message: '验证码', trigger: 'blur' }">
                                <div class="code">
                                    <el-input v-model="userInfo.code" placeholder="请输入验证码"></el-input>

                                    <el-button
                                        :loading="sending"
                                        @click="sendMsg"
                                        type="primary"
                                        :disabled="time !== 0"
                                    >
                                        {{ time === 0 ? '发送验证码' : `重新发送（${time}s）` }}
                                    </el-button>
                                </div>
                            </el-form-item>
                            <!-- <el-form-item>
                                <el-button :loading="loading" @click="login"
                                    type="primary" style="width: 100%;">登录
                                </el-button>
                            </el-form-item> -->
                            <el-form-item>
                                <el-button :loading="loading" @click="phonelogin" type="primary" style="width: 100%"
                                    >手机登录</el-button
                                >
                            </el-form-item>
                            <el-form-item label="">
                                <el-checkbox v-model="rememberMe">7天内免登录 </el-checkbox>
                                <el-button type="text" style="float: right" @click="register = true"
                                    >注册账号
                                </el-button>
                            </el-form-item>
                        </el-form>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    data() {
        return {
            rememberMe: false,
            loading: false,
            userInfo: {
                username: '',
                password: '',
                phone: '',
                code: ''
            },
            register: false,
            registerInfo: {
                username: '',
                password: ''
            },
            activeName: 'first',
            time: 0,
            sending: false
        };
    },
    methods: {
        login() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/auth/login', {
                            username: this.userInfo.username,
                            password: this.userInfo.password,
                            remember: this.rememberMe
                        })
                        .then(res => {
                            localStorage.setItem('token', res);
                            return this.$http.get('/user/my');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateUserInfo', res);
                            this.$router.replace('/dashboard');
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        phonelogin() {
            this.$refs.form2.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/auth/phoneLogin', {
                            phone: this.userInfo.phone
                        })
                        .then(res => {
                            localStorage.setItem('token', res);
                            return this.$http.get('/user/my');
                        })
                        .then(res => {
                            this.loading = false;
                            this.$store.commit('updateUserInfo', res);
                            this.$router.replace({
                                name: this.$route.params.name || 'dashboard'
                            });
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        doRegister() {
            this.$refs.registerForm.validate(valid => {
                if (valid) {
                    this.loading = true;
                    this.$http
                        .post('/user/register', {
                            username: this.registerInfo.username,
                            password: this.registerInfo.password
                        })
                        .then(res => {
                            this.loading = false;
                            this.$message.success('注册成功');
                            this.register = false;
                        })
                        .catch(e => {
                            console.log(e);
                            this.loading = false;
                            this.$message.error(e.error);
                        });
                }
            });
        },
        sendMsg() {
            if (!/^1[3-9]\d{9}$/.test(this.userInfo.phone)) {
                this.$message.warning('手机号格式错误');
                return;
            }
            this.sending = true;
            this.$http
                .get('/sms/sendVerify', {
                    phone: this.userInfo.phone
                })
                .then(res => {
                    this.sending = false;
                    this.checkTime();
                })
                .catch(e => {
                    this.sending = false;
                    console.log(e);
                    this.$message.error(e.error);
                });
        },
        checkTime() {
            this.time = 60;
            let i = setInterval(() => {
                this.time--;
                if (this.time === 0) {
                    clearInterval(i);
                }
            }, 1000);
        }
    }
};
</script>
<style lang="less" scoped>
.container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ebebeb;
    background-image: url('https://microball.oss-cn-hangzhou.aliyuncs.com/izouma/application/rawpixel-com-565462-unsplash%20%281%29.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
}

.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    background: white;
    border: 1px #eaeaea solid;
    position: absolute;
    right: 100px;
    top: 100px;
    width: 400px;

    .title {
        color: #20a0ff;
        font-weight: bold;
        width: 350px;
        line-height: 60px;
    }
    .register-title {
        color: #20a0ff;
        font-weight: bold;
        font-size: 15px;
    }
}
@media screen and (max-width: 600px) {
    .login-wrapper {
        right: 0;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        height: 265px;
        width: calc(100vw - 30px);
    }
}
.slide-in-enter {
    opacity: 0;
    transform: translateX(50%);
}
.slide-in-enter-active {
    transition: all 0.3s;
}
.slide-in-leave-active {
    opacity: 0;
    transform: translateX(-50%);
    transition: all 0.3s;
}
.slide-out-enter {
    opacity: 0;
    transform: translateX(-50%);
}
.slide-out-enter-active {
    transition: all 0.3s;
}
.slide-out-leave-active {
    opacity: 0;
    transform: translateX(50%);
    transition: all 0.3s;
}

.tab-list {
    width: 350px;
}

.code {
    display: flex;
    .el-button {
        margin-left: 15px;
        min-width: 120px;
    }
}
</style>
